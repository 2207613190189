exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advice-index-js": () => import("./../../../src/pages/advice/index.js" /* webpackChunkName: "component---src-pages-advice-index-js" */),
  "component---src-pages-gear-index-js": () => import("./../../../src/pages/gear/index.js" /* webpackChunkName: "component---src-pages-gear-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-index-js": () => import("./../../../src/pages/info/index.js" /* webpackChunkName: "component---src-pages-info-index-js" */),
  "component---src-pages-reviews-index-js": () => import("./../../../src/pages/reviews/index.js" /* webpackChunkName: "component---src-pages-reviews-index-js" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-10-best-asmr-keyboards-2022-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/10-best-asmr-keyboards-2022/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-10-best-asmr-keyboards-2022-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-10-best-cheap-streaming-pc-buyers-guide-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/10-best-cheap-streaming-pc-buyers-guide/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-10-best-cheap-streaming-pc-buyers-guide-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-10-best-cooking-asmr-tiktok-accounts-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/10-best-cooking-asmr-tiktok-accounts/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-10-best-cooking-asmr-tiktok-accounts-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-10-essential-asmr-equipment-for-beginners-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/10-essential-asmr-equipment-for-beginners/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-10-essential-asmr-equipment-for-beginners-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-10-most-common-asmr-triggers-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/10-most-common-asmr-triggers/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-10-most-common-asmr-triggers-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-10-popular-mukbang-food-ideas-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/10-popular-mukbang-food-ideas/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-10-popular-mukbang-food-ideas-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-12-best-asmr-noises-popular-asmr-triggers-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/12-best-asmr-noises-popular-asmr-triggers/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-12-best-asmr-noises-popular-asmr-triggers-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-12-best-camera-for-youtube-asmr-2022-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/12-best-camera-for-youtube-asmr-2022/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-12-best-camera-for-youtube-asmr-2022-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-12-best-food-asmr-channels-on-youtube-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/12-best-food-asmr-channels-on-youtube/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-12-best-food-asmr-channels-on-youtube-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-12-best-streaming-desk-2022-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/12-best-streaming-desk-2022/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-12-best-streaming-desk-2022-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-3-best-asian-asmr-youtube-channels-to-watch-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/3-best-asian-asmr-youtube-channels-to-watch/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-3-best-asian-asmr-youtube-channels-to-watch-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-3-best-asmr-headphones-in-2022-complete-buying-guide-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/3-best-asmr-headphones-in-2022-complete-buying-guide/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-3-best-asmr-headphones-in-2022-complete-buying-guide-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-3-dio-free-space-binaural-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/3dio-free-space-binaural/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-3-dio-free-space-binaural-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-3-dio-free-space-pro-ii-microphone-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/3dio-free-space-pro-ii-microphone/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-3-dio-free-space-pro-ii-microphone-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-6-must-watch-beauty-asmr-videos-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/6-must-watch-beauty-asmr-videos/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-6-must-watch-beauty-asmr-videos-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-8-best-binaural-headphones-reviews-and-buying-guide-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/8-best-binaural-headphones-reviews-and-buying-guide/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-8-best-binaural-headphones-reviews-and-buying-guide-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-about-us-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/about-us/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-about-us-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-adhd-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/asmr-adhd/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-adhd-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-annoying-sounds-why-not-appreciate-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/asmr-annoying-sounds-why-not-appreciate/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-annoying-sounds-why-not-appreciate-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-chiropractic-adjustment-meaning-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/asmr-chiropractic-adjustment-meaning/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-chiropractic-adjustment-meaning-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-darling-net-worth-and-basic-information-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/asmr-darling-net-worth-and-basic-information/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-darling-net-worth-and-basic-information-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-dummy-head-microphone-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/asmr-dummy-head-microphone/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-dummy-head-microphone-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-earbud-mic-5-best-picks-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/asmr-earbud-mic-5-best-picks/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-earbud-mic-5-best-picks-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-for-sleep-does-it-work-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/asmr-for-sleep-does-it-work/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-for-sleep-does-it-work-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-makes-me-angry-heres-why-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/asmr-makes-me-angry-heres-why/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-makes-me-angry-heres-why-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-music-is-popular-in-the-music-industry-why-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/asmr-music-is-popular-in-the-music-industry-why/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-music-is-popular-in-the-music-industry-why-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-script-for-relaxation-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/asmr-script-for-relaxation/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-script-for-relaxation-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-setup-how-to-start-recording-your-first-asmr-today-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/asmr-setup-how-to-start-recording-your-first-asmr-today/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-setup-how-to-start-recording-your-first-asmr-today-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-skincare-routine-meaning-beauty-routine-videos-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/asmr-skincare-routine-meaning-beauty-routine-videos/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-skincare-routine-meaning-beauty-routine-videos-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-sounds-that-will-make-you-fall-asleep-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/asmr-sounds-that-will-make-you-fall-asleep/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-sounds-that-will-make-you-fall-asleep-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-videos-can-help-you-reduce-anxiety-know-why-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/asmr-videos-can-help-you-reduce-anxiety-know-why/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-videos-can-help-you-reduce-anxiety-know-why-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-voice-whispering-why-do-people-enjoy-listening-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/asmr-voice-whispering-why-do-people-enjoy-listening/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-voice-whispering-why-do-people-enjoy-listening-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-zeitgeist-microphone-and-other-equipments-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/asmr-zeitgeist-microphone-and-other-equipments/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-asmr-zeitgeist-microphone-and-other-equipments-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-atr-2100-microphone-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/atr2100-microphone/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-atr-2100-microphone-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-audio-technica-at-2020-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/audio-technica-at2020/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-audio-technica-at-2020-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-baba-sen-famous-asmr-artist-aka-the-cosmic-barber-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/baba-sen-famous-asmr-artist-aka-the-cosmic-barber/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-baba-sen-famous-asmr-artist-aka-the-cosmic-barber-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-beginner-asmr-microphone-4-affordable-choices-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/beginner-asmr-microphone-4-affordable-choices/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-beginner-asmr-microphone-4-affordable-choices-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-best-12-asmr-artist-asmrtist-on-youtube-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/best-12-asmr-artist-asmrtist-on-youtube/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-best-12-asmr-artist-asmrtist-on-youtube-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-best-asmr-foods-to-eat-crunchy-foods-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/best-asmr-foods-to-eat-crunchy-foods/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-best-asmr-foods-to-eat-crunchy-foods-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-best-asmr-gifts-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/best-asmr-gifts/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-best-asmr-gifts-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-best-asmr-ideas-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/best-asmr-ideas/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-best-asmr-ideas-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-best-asmr-podcast-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/best-asmr-podcast/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-best-asmr-podcast-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-best-audio-interface-for-guitar-10-best-picks-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/best-audio-interface-for-guitar-10-best-picks/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-best-audio-interface-for-guitar-10-best-picks-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-best-audio-interface-for-mac-2022-top-8-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/best-audio-interface-for-mac-2022-top-8/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-best-audio-interface-for-mac-2022-top-8-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-best-audio-interface-for-streaming-ultimate-buyers-guide-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/best-audio-interface-for-streaming-ultimate-buyers-guide/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-best-audio-interface-for-streaming-ultimate-buyers-guide-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-best-shure-sm-7-b-mic-stand-buying-guide-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/best-shure-sm7b-mic-stand-buying-guide/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-best-shure-sm-7-b-mic-stand-buying-guide-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-best-way-to-enjoy-asmr-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/best-way-to-enjoy-asmr/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-best-way-to-enjoy-asmr-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-best-wireless-earbuds-for-asmr-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/best-wireless-earbuds-for-asmr/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-best-wireless-earbuds-for-asmr-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-blue-snowball-ice-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/blue-snowball-ice/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-blue-snowball-ice-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-blue-yeti-usb-microphone-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/blue-yeti-usb-microphone/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-blue-yeti-usb-microphone-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-bob-ross-asmr-artist-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/bob-ross-asmr-artist/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-bob-ross-asmr-artist-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-bob-ross-best-asmr-5-best-picks-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/bob-ross-best-asmr-5-best-picks/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-bob-ross-best-asmr-5-best-picks-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-cheap-audio-interface-8-best-picks-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/cheap-audio-interface-8-best-picks/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-cheap-audio-interface-8-best-picks-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-diamond-asmr-artist-basic-information-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/diamond-asmr-artist-basic-information/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-diamond-asmr-artist-basic-information-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-diy-asmr-microphone-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/diy-asmr-microphone/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-diy-asmr-microphone-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-dr-t-asmr-artist-basic-information-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/dr-t-asmr-artist-basic-information/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-dr-t-asmr-artist-basic-information-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-eight-best-asmr-videos-to-help-you-fall-asleep-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/eight-best-asmr-videos-to-help-you-fall-asleep/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-eight-best-asmr-videos-to-help-you-fall-asleep-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-elgato-key-light-alternative-6-cheaper-options-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/elgato-key-light-alternative-6-cheaper-options/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-elgato-key-light-alternative-6-cheaper-options-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-etymotic-er-2-xr-pros-and-cons-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/etymotic-er2xr-pros-and-cons/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-etymotic-er-2-xr-pros-and-cons-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-everything-you-need-to-know-about-hoomband-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/everything-you-need-to-know-about-hoomband/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-everything-you-need-to-know-about-hoomband-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-fuzzi-asmr-artist-everything-you-should-know-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/fuzzi-asmr-artist-everything-you-should-know/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-fuzzi-asmr-artist-everything-you-should-know-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-hiperx-quadcast-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/hiperx-quadcast/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-hiperx-quadcast-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-much-do-asmr-youtubers-make-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/how-much-do-asmr-youtubers-make/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-much-do-asmr-youtubers-make-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-choose-the-best-camcorders-mic-for-you-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/how-to-choose-the-best-camcorders-mic-for-you/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-choose-the-best-camcorders-mic-for-you-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-do-asmr-videos-complete-guide-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/how-to-do-asmr-videos-complete-guide/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-do-asmr-videos-complete-guide-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-do-asmr-without-a-mic-5-tips-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/how-to-do-asmr-without-a-mic-5-tips/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-do-asmr-without-a-mic-5-tips-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-make-asmr-food-videos-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/how-to-make-asmr-food-videos/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-make-asmr-food-videos-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-make-asmr-videos-a-guide-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/how-to-make-asmr-videos-a-guide/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-make-asmr-videos-a-guide-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-make-satisfying-sand-for-asmr-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/how-to-make-satisfying-sand-for-asmr/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-make-satisfying-sand-for-asmr-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-make-your-own-asmr-tiktok-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/how-to-make-your-own-asmr-tiktok/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-make-your-own-asmr-tiktok-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-make-your-own-mukbang-video-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/how-to-make-your-own-mukbang-video/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-make-your-own-mukbang-video-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-make-your-voice-sound-deeper-on-mic-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/how-to-make-your-voice-sound-deeper-on-mic/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-make-your-voice-sound-deeper-on-mic-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-pair-sony-wh-1000-xm-4-with-an-iphone-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/how-to-pair-sony-wh-1000xm4-with-an-iphone/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-pair-sony-wh-1000-xm-4-with-an-iphone-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-record-asmr-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/how-to-record-asmr/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-record-asmr-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-record-videos-using-asmr-microphone-for-iphone-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/how-to-record-videos-using-asmr-microphone-for-iphone/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-record-videos-using-asmr-microphone-for-iphone-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-start-a-mukbang-channel-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/how-to-start-a-mukbang-channel/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-start-a-mukbang-channel-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-stop-lip-smacking-while-recording-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/how-to-stop-lip-smacking-while-recording/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-stop-lip-smacking-while-recording-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-mitra-corp-3-d-mic-pro-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/mitra-corp-3d-mic-pro/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-mitra-corp-3-d-mic-pro-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-mommy-tang-vegan-food-korean-influencers-basic-information-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/mommy-tang-vegan-food-korean-influencers-basic-information/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-mommy-tang-vegan-food-korean-influencers-basic-information-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-pj-dreams-asmr-artist-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/pj-dreams-asmr-artist/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-pj-dreams-asmr-artist-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-privacy-policy-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/privacy-policy/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-privacy-policy-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-rode-nt-1-a-microphone-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/rode-nt1-a-microphone/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-rode-nt-1-a-microphone-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-rode-nt-5-microphone-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/rode-nt5-microphone/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-rode-nt-5-microphone-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-roland-cs-10-em-microphone-earphones-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/roland-cs-10em-microphone-earphones/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-roland-cs-10-em-microphone-earphones-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-samson-go-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/samson-go/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-samson-go-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-sas-asmr-net-worth-and-basic-information-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/sas-asmr-net-worth-and-basic-information/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-sas-asmr-net-worth-and-basic-information-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-sennheiser-ambeo-vr-microphone-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/sennheiser-ambeo-vr-microphone/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-sennheiser-ambeo-vr-microphone-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-shure-sm-7-b-review-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/shure-sm7b-review/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-shure-sm-7-b-review-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-sony-wh-1000-xm-4-vs-bose-qc-35-ii-their-difference-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/sony-wh-1000xm4-vs-bose-qc35-ii-their-difference/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-sony-wh-1000-xm-4-vs-bose-qc-35-ii-their-difference-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-streaming-monitor-8-best-picks-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/streaming-monitor-8-best-picks/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-streaming-monitor-8-best-picks-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-streaming-room-setup-all-the-equipments-you-need-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/streaming-room-setup-all-the-equipments-you-need/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-streaming-room-setup-all-the-equipments-you-need-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-tascam-dr-05-portable-digital-recorder-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/tascam-dr-05-portable-digital-recorder/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-tascam-dr-05-portable-digital-recorder-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-terms-and-conditions-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/terms-and-conditions/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-terms-and-conditions-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-the-best-earbuds-for-asmr-top-5-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/the-best-earbuds-for-asmr-top-5/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-the-best-earbuds-for-asmr-top-5-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-the-best-mukbang-setup-to-get-you-started-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/the-best-mukbang-setup-to-get-you-started/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-the-best-mukbang-setup-to-get-you-started-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-tingting-asmr-basic-information-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/tingting-asmr-basic-information/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-tingting-asmr-basic-information-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-what-are-intentional-and-unintentional-asmr-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/what-are-intentional-and-unintentional-asmr/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-what-are-intentional-and-unintentional-asmr-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-what-are-the-benefits-of-visual-asmr-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/what-are-the-benefits-of-visual-asmr/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-what-are-the-benefits-of-visual-asmr-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-what-are-the-best-asmr-foods-to-buy-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/what-are-the-best-asmr-foods-to-buy/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-what-are-the-best-asmr-foods-to-buy-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-what-asmr-is-best-for-me-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/what-asmr-is-best-for-me/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-what-asmr-is-best-for-me-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-what-does-asmr-mean-for-food-why-its-popular-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/what-does-asmr-mean-for-food-why-its-popular/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-what-does-asmr-mean-for-food-why-its-popular-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-what-does-asmr-on-youtube-mean-why-trending-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/what-does-asmr-on-youtube-mean-why-trending/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-what-does-asmr-on-youtube-mean-why-trending-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-what-does-the-word-asmr-mean-facts-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/what-does-the-word-asmr-mean-facts/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-what-does-the-word-asmr-mean-facts-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-what-is-an-asmr-massage-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/what-is-an-asmr-massage/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-what-is-an-asmr-massage-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-what-is-asmr-roleplay-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/what-is-asmr-roleplay/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-what-is-asmr-roleplay-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-what-is-asmr-slicing-game-who-can-play-this-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/what-is-asmr-slicing-game-who-can-play-this/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-what-is-asmr-slicing-game-who-can-play-this-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-what-is-binaural-audio-and-its-connection-with-asmr-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/what-is-binaural-audio-and-its-connection-with-asmr/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-what-is-binaural-audio-and-its-connection-with-asmr-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-who-is-the-best-asmr-artist-top-12-asmrtist-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/who-is-the-best-asmr-artist-top-12-asmrtist/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-who-is-the-best-asmr-artist-top-12-asmrtist-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-zoom-h-1-handy-recorder-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/zoom-h1-handy-recorder/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-zoom-h-1-handy-recorder-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-zoom-h-4-n-handy-recorder-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/workspace/content/blog/zoom-h4n-handy-recorder/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-zoom-h-4-n-handy-recorder-index-md" */)
}

